export const MAX_ATTACHMENTS = 4

export const MeasuredUnit = {
  ud: {
    label: 'Unidad',
    value: 'ud',
    group: 'Unidad',
  },
  kg: {
    label: 'Kilogramo',
    value: 'kg',
    group: 'Peso',
  },
  gr: {
    label: 'Gramo',
    value: 'gr',
    group: 'Peso',
  },
  m: {
    label: 'Metro',
    value: 'm',
    group: 'Longitud',
  },
  cm: {
    label: 'Centímetro',
    value: 'cm',
    group: 'Longitud',
  },
  mm: {
    label: 'Milímetro',
    value: 'mm',
    group: 'Longitud',
  },
  in: {
    label: 'Pulgada',
    value: 'in',
    group: 'Longitud',
  },
  ft: {
    label: 'Pie',
    value: 'ft',
    group: 'Longitud',
  },
  m2: {
    label: 'Metro cuadrado',
    value: 'm2',
    group: 'Área',
  },
  cm2: {
    label: 'Centímetro cuadrado',
    value: 'cm2',
    group: 'Área',
  },
  mm2: {
    label: 'Milímetro cuadrado',
    value: 'mm2',
    group: 'Área',
  },
  in2: {
    label: 'Pulgada cuadrada',
    value: 'in2',
    group: 'Área',
  },
  ft2: {
    label: 'Pie cuadrado',
    value: 'ft2',
    group: 'Área',
  },
  m3: {
    label: 'Metro cúbico',
    value: 'm3',
    group: 'Volumen',
  },
  cm3: {
    label: 'Centímetro cúbico',
    value: 'cm3',
    group: 'Volumen',
  },
  mm3: {
    label: 'Milímetro cúbico',
    value: 'mm3',
    group: 'Volumen',
  },
  in3: {
    label: 'Pulgada cúbica',
    value: 'in3',
    group: 'Volumen',
  },
  ft3: {
    label: 'Pie cúbico',
    value: 'ft3',
    group: 'Volumen',
  },
  l: {
    label: 'Litro',
    value: 'l',
    group: 'Volumen',
  },
  ml: {
    label: 'Mililitro',
    value: 'ml',
    group: 'Volumen',
  },
  hp: {
    label: 'Caballos de fuerza',
    value: 'hp',
    group: 'Potencia',
  },
}
