import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

import type { UserType } from '@customTypes/user'
import { getColorScheme } from '@utils/styles'

const VERSION_STORAGE = 1

// BASE STORE
type StoreState = {
  user: UserType | null
}

type Action = {
  setUser: (user: StoreState['user'] | null) => void
  reset: () => void
}

export const useStoreBase = create(
  persist<StoreState & Action>(
    (set) => ({
      user: null,
      theme: getColorScheme(),
      setUser: (user) => set(() => ({ user })),
      reset: () => set(() => ({ user: null })),
    }),
    {
      name: `storage-base-${VERSION_STORAGE}`,
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
