/* eslint-disable react-hooks/rules-of-hooks */
import Input from '@components/shared/input'
import NumberFormat from '@components/shared/Number'
import { MeasuredUnit } from '@constants/rawMaterial'
import { BlockProformaType } from '@customTypes/proforma'
import React from 'react'

type BlockResumenParams = {
  index?: number
  reduced?: boolean
  block: BlockProformaType
  userRole: string
  handleInput?: (path: string, value: number | string) => void
}

export const getBlockResumen = (params: BlockResumenParams) => {
  const { block, reduced, index, handleInput, userRole } = params

  const { receipts } = block

  if (reduced) {
    return `La oferta incluye: ${receipts
      .map(({ rawMaterials }) => rawMaterials.map((material) => material.name).join(', '))
      .join(', ')}.`
  }

  return (
    <React.Fragment>
      <ol>
        {receipts.map((receipt, idxReceipt) => (
          <li key={receipt.uid}>
            {receipt.name}
            <ul>
              {receipt.rawMaterials.map((material, idxRm) => {
                if (reduced) {
                  return (
                    <li key={material.uid}>
                      {material.name} {material.quantity} {MeasuredUnit[material.measure].value}
                    </li>
                  )
                }

                return (
                  <li className="cd-my-[0.75rem]" key={material.uid}>
                    {material.name}: {material.quantity} {MeasuredUnit[material.measure].value}
                    {handleInput && (
                      <Input
                        defaultValue={material.value}
                        disabled={userRole !== 'admin'}
                        display={userRole !== 'admin' ? 'none' : 'block'}
                        min={0}
                        prefix="$"
                        readOnly={userRole !== 'admin'}
                        typeInput="number"
                        variant="default"
                        onChange={(value) =>
                          handleInput(
                            `items.${index}.receipts.${idxReceipt}.rawMaterials.${idxRm}.value`,
                            value,
                          )
                        }
                      />
                    )}
                    {!handleInput && <span>. Valor: </span>}
                    {!handleInput && <NumberFormat value={material.value} />}
                  </li>
                )
              })}
            </ul>
          </li>
        ))}
      </ol>
    </React.Fragment>
  )
}
