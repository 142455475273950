export const UI = {
  Size: 'sm',
  TableMinWidth: 800,
  TableMinWidthSmall: 500,
  TableMinWidthMobile: 300,
}

export const LIMIT_SELECT_SEARCH = 25

export const MAX_NUMBER_VALUE = 100_000

export const MAX_TIME_REPEAT = 24

export const CACHED_TIME = 4 * 60 * 60 * 1000

export const STALE_TIME = 30 * 60 * 1000

export const MAX_FILE_SIZE = 25 * 1024 * 1024

export const TIME_FORMAT = 'HH:mm'

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'

export const DATE_FORMAT_SHORT = 'DD/MM/YYYY'

export const WEB_VERSION = '1.8.6'

export const PDF_PROFORMA_ID = 'pdf-proforma'

export const PDF_ORDER_PURCHASE_ID = 'pdf-order-purchase'
