export const capitalize = (s: string, onlyFirst = false) => {
  if (onlyFirst) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  return s
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const cleanNumber = (value: string) => {
  return value.replace(/[^0-9.]/g, '')
}

export const getColorStock = (stock: number, alertStock: number) => {
  if (stock <= 0) return 'red'
  if (stock <= alertStock) return 'yellow'

  return 'inherit'
}

export const validateFormat = (value: string, regex: RegExp) => {
  return regex.test(value)
}

export const periodString = (value: string) => {
  const lastChar = value.charAt(value.length - 1)

  if (lastChar === '.') return value

  return `${value}.`
}

export const ellipsis = (value: string, length: number = 40) => {
  if (value.length <= length) return value

  return `${value.substring(0, length)}...`
}

export const formatNumber = (value: number, digits = 2, prefix?: string, suffix?: string) => {
  const valueFormat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(value)

  return `${prefix || ''}${valueFormat}${suffix || ''}`
}
