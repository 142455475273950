/* eslint-disable react-hooks/rules-of-hooks */
import Input from '@components/shared/input'
import { GetRawMaterialsType, RawMaterialBaseType } from '@customTypes/rawMaterial'
import { RawMaterialReceiptType } from '@customTypes/receipt'
import { periodString } from './string'
import { advancedSearch } from './form'

type TotalParams = {
  value: number
  quantity: number
  percentage: number
  hasAccessory: boolean
}

export const getTotal = (params: TotalParams) => {
  const { value, quantity, percentage, hasAccessory: hasAccessory } = params

  let result = value * quantity

  if (hasAccessory) {
    result *= 1 + percentage / 100
  }

  return Number(result.toFixed(2))
}

type SearchParams<T> = {
  params: GetRawMaterialsType
  data?: T[]
}

export const onFilterRawMaterial = <T extends RawMaterialBaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data = [],
  } = params

  if (!query || !field || !data) {
    return data
  }

  if (['name', 'description', 'measure'].includes(field)) {
    const keyField = field as 'name' | 'description' | 'measure'
    return data.filter((item) =>
      advancedSearch({ search: query.toLowerCase(), label: item[keyField].toLowerCase() }),
    )
  }

  if (field === 'uid') {
    return data.filter((item) => item.uid.startsWith(query))
  }

  return data
}

export const filterRawMaterialsAttachments = (rawMaterials: RawMaterialBaseType[]) => {
  const attachmentMap = new Map<string, RawMaterialBaseType>()

  for (const rawMaterial of rawMaterials) {
    if (rawMaterial.attachments.length > 0) {
      const keyAttachment = rawMaterial.attachments.join('')
      attachmentMap.set(keyAttachment, rawMaterial)
    }
  }

  return Array.from(attachmentMap.values())
}

type RawMaterialDescriptionParams = {
  reduced?: boolean
  index?: number
  rawMaterial: RawMaterialReceiptType
  userRole: string
  handleInput?: (path: string, value: number | string) => void
}

export const getRawMaterialResumen = (params: RawMaterialDescriptionParams) => {
  const { rawMaterial, reduced, index, userRole, handleInput } = params

  if (reduced) {
    return periodString(rawMaterial.description)
  }

  return (
    <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem]">
      <span>{rawMaterial.description}</span>
      {handleInput && (
        <Input
          defaultValue={rawMaterial.value}
          disabled={userRole !== 'admin'}
          display={userRole !== 'admin' ? 'none' : 'block'}
          min={0}
          prefix="$"
          readOnly={userRole !== 'admin'}
          typeInput="number"
          variant="default"
          onChange={(value) => handleInput(`items.${index}.value`, value)}
        />
      )}
    </div>
  )
}
